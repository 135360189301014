<script lang="ts" setup>
import type { CmsBlockCategoryText } from '~/types/models/cms/cmsBlockTypes';

const props = defineProps<{
    content: CmsBlockCategoryText;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slotContent = getSlotContent('content');
</script>
<template>
    <div class="cms-block-category-text">
        <CmsGenericElement :content="slotContent" />
    </div>
</template>
